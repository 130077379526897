import Footer from "../../Footer/Footer"
import LandPage from "../../LandPage/LandPage"
import Navbar from "../../Navbar/Navbar"
import img from '../../../Images/1c64adf40db0fddfd2742beb31347e79.jpeg'
import img1 from '../../../Images/categ1.png'
import img2 from '../../../Images/categ2.png'
import img3 from '../../../Images/categ3.png'
import img4 from '../../../Images/categ2.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './SingleCategory.css';
import {Navigation, Pagination } from 'swiper/modules';
import SwiperCore from 'swiper';
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
// import { useEffect, useState } from "react"
// import axios from "axios"
// import { useParams } from "react-router-dom"
SwiperCore.use([Navigation]);


// import { useState } from "react"
function SingleCategory(){
    const nav = useNavigate()
    const handleHomeBtn=()=>{
        nav('/')
    }
    return(
        <>
            <Navbar/>
            <LandPage header = "our category" pragraph="we offer every thing that is special for you" img ={img} />
            <div className="singlecategory-container">
                <div className="relative-links">
                    <button onClick={handleHomeBtn}>home</button>
                    <FontAwesomeIcon icon={faAngleRight}/>
                    <button>our category</button>
                </div>
                <Swiper
                    slidesPerView={4}
                    centeredSlides={true}
                    spaceBetween={100}
                    grabCursor={true}
                    clickable={true}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    <SwiperSlide><img  className="img" src={img1} alt="img"/></SwiperSlide>
                    <SwiperSlide><img  className="img" src={img2} alt="img"/></SwiperSlide>
                    <SwiperSlide><img  className="img" src={img3} alt="img"/></SwiperSlide>
                    <SwiperSlide><img  className="img" src={img4} alt="img"/></SwiperSlide>
                    <SwiperSlide><img  className="img" src={img1} alt="img"/></SwiperSlide>
                    <SwiperSlide><img  className="img" src={img2} alt="img"/></SwiperSlide>
                    <SwiperSlide><img  className="img" src={img3} alt="img"/></SwiperSlide>
                    <SwiperSlide><img  className="img" src={img4} alt="img"/></SwiperSlide>
                </Swiper>
                <div>
                    <h1>category name</h1>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some, </p>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, </p>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, </p>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, </p>
                </div>
            </div>
            <Footer/>  
        </>
    )
}
export default SingleCategory