import Footer from "../Footer/Footer"
import Navbar from "../Navbar/Navbar"
import './Home.css';
import HomeAbout from "./HomeAbout/HomeAbout";
import HomeBlogs from "./HomeBlogs/HomeBlogs";
import HomeCateg from "./HomeCateg/HomeCateg";
import HomeContact from "./HomeContact/HomeContact";
import HomeLanPage from "./HomeLanPage/HomeLanPage";
function Home(){
    return(
        <>
        <Navbar/>
        <div className="home-container">
            <HomeLanPage/>
            <HomeAbout/>
            <HomeCateg/>
            <HomeBlogs/>
            <HomeContact/>
        </div>
        <Footer/>
        </>
    )
}
export default Home