import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './BlogsFilter.css'
function BlogsFilter(){
    return(
        <div className="blogsFilter-container">
            <div className='first'>
                <h4>filter</h4>
                <FontAwesomeIcon className='iconFilter' icon={faFilter} />
            </div>
            <div className='second'>
                <h4>blog date</h4>
                <select>
                    <option>blog date here</option>
                    <option>one</option>
                    <option>one</option>
                </select>
            </div>
            <div className='third'>
                <h4>top blgs</h4>
                <div>
                    <input type="checkbox"/>
                    <label>top blogs</label>
                </div>
                <div>
                    <input type="checkbox"/>
                    <label>top blogs</label>
                </div>
                <div>
                    <input type="checkbox"/>
                    <label>top blogs</label>
                </div>
                <div>
                    <input type="checkbox"/>
                    <label>top blogs</label>
                </div>
            </div>
            <div className='fourth'>
                <h4>top blgs</h4>
                <div>
                    <input type="checkbox"/>
                    <label>top blogs</label>
                </div>
                <div>
                    <input type="checkbox"/>
                    <label>top blogs</label>
                </div>
                <div>
                    <input type="checkbox"/>
                    <label>top blogs</label>
                </div>
                <div>
                    <input type="checkbox"/>
                    <label>top blogs</label>
                </div>
            </div>
        </div>
    )
}
export default BlogsFilter