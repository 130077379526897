import axios from "axios"
import'./ProductDetails.css'
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Footer from "../../Footer/Footer"
import Navbar from "../../Navbar/Navbar"
import img from '../../../Images/db8aed210d7d6a68fb0f7874432dbe58.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAdd, faDownload, faPrint, faShare } from "@fortawesome/free-solid-svg-icons"
import ProductFilter from "../../ProductFilter/ProductFilter"

function ProductDetails(){
    const param = useParams()
    const [product , setProduct] = useState([])
    useEffect(()=>{
        axios.get(`https://jsonplaceholder.typicode.com/photos/${param.ID}`)
        .then(res => setProduct(res.data))
    })
    console.log(product)
    return(
        <>
            <Navbar/>
            <ProductFilter/>
            <div className="details-container">
                <div className="details-content">
                    <div className="details-img">
                        <img src={img} alt="img"/>
                    </div>
                    <div className="details">
                        <div className="header">
                            <h2>product name</h2>
                            <h4>product Solution </h4>       
                        </div>
                        <div className="detailsInfo">
                            <div>
                                <h1>information</h1>
                                <FontAwesomeIcon className="detailsIcon" icon={faAdd}/>
                            </div>
                            <div>
                                <h1>sorts</h1>
                                <FontAwesomeIcon className="detailsIcon" icon={faAdd}/>
                            </div>
                            <div>
                                <h1>solution</h1>
                                <FontAwesomeIcon className="detailsIcon" icon={faAdd}/>
                            </div>
                        </div>
                        <div className="detailsIcon">
                            <a href="/"><FontAwesomeIcon icon={faPrint} />print</a>
                            <a href="/"><FontAwesomeIcon icon={faDownload}/> download</a>
                            <a href="/"><FontAwesomeIcon icon={faShare}/> share</a>
                        </div>

                    </div>
                </div>
                <button>buy now</button>
            </div>
            <Footer/>
        </>
    )
}
export default ProductDetails