import './Navbar.css'
import img from '../../Images/c69bf24aaf55507fe01b0603ee38a83b.png'
import { useNavigate, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
function Navbar(){
    const [show , setShow] =useState(false)
    const hanldeBars=()=>{
        setShow(!show)
    }

    const nav = useNavigate()
    const handleButton=()=>{
        nav('/contact')
    }
    return(
        <div className="navbar-container">
            <div className="logo">
                <img src={img} alt ="logo"/>
            </div>
            <ul className='nav-links'>
                <li><NavLink to="/#">home</NavLink></li>
                <li><NavLink to="/about">about us</NavLink></li>
                <li><NavLink to="/products">products</NavLink></li>
                <li><NavLink to="/market">market place</NavLink></li>
                <li><NavLink to="/blogs">blogs</NavLink></li>
                <li><NavLink to="/contact">contact us</NavLink></li>
            </ul>
            <div className='nav-btn'>
                <button onClick={handleButton}>contact us now</button>
            </div>
            <div className='bars' onClick={hanldeBars}>
                <FontAwesomeIcon icon={faBars}/>
            </div>
            <ul className='sideBar'style={{display:show?"flex":"none"}}>
                <div className='bars' onClick={hanldeBars}>
                    <FontAwesomeIcon icon={faX}/>
                </div>
                <li><NavLink to="/#">home</NavLink></li>
                <li><NavLink to="/about">about us</NavLink></li>
                <li><NavLink to="/products">products</NavLink></li>
                <li><NavLink to="/market">market place</NavLink></li>
                <li><NavLink to="/blogs">blogs</NavLink></li>
                <li><NavLink to="/contact">contact us</NavLink></li>
            </ul>
        </div>
    )
}
export default Navbar