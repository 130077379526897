import './ProductFilter.css'
function ProductFilter(){
    return(
        <div className='productFilter-container'>
            <div>
                <h4>commerical lines</h4>
                <select name="cars" id="cars">
                  <option value="volvo">all commerical lines</option>
                  <option value="saab">Saab</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="audi">Audi</option>
                </select>
            </div>
            <div>
                <h4>commerical category</h4>
                <select name="cars" id="cars">
                  <option value="all commerical category">all commerical category</option>
                  <option value="saab">Saab</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="audi">Audi</option>
                </select>
            </div>
            <div>
                <h4>species</h4>
                <select name="cars" id="cars">
                  <option value="volvo">species</option>
                  <option value="saab">Saab</option>
                  <option value="mercedes">Mercedes</option>
                  <option value="audi">Audi</option>
                </select>
            </div>
        </div>
    )
}
export default ProductFilter