import { useEffect, useState } from 'react'
import './Item.css'
import medicen from '../../../Images/db8aed210d7d6a68fb0f7874432dbe58.png'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import GreenBtn from '../../GreenBtn/GreenBtn';
function Item(){
    const nav = useNavigate()
    const handleViewBtn =(e)=>{
        nav(`/products/${e}`)
    }
    const [data , setData] = useState([])
    useEffect(() =>{
       axios.get('https://jsonplaceholder.typicode.com/photos')
       .then(res=> {return res})
       .then(res=> setData(res.data))
    })
    data.length = 10
    const card = data.map(item => {
       return(
           <div className='card'>
               <img src={medicen} alt="d"/>
               <div className='content'>
                    <h3>product name</h3>
                    <p>Injectable Solution</p>
                    <GreenBtn value="view product" func={()=>handleViewBtn(item.id)}/>
               </div>
           </div>
       )
    })
    return(
        <div className='product-cards'>
            {card}
        </div>
    )
}
export default Item