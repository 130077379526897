import Footer from "../Footer/Footer"
import LandPage from "../LandPage/LandPage"
import Navbar from "../Navbar/Navbar"
import ContactForm from "./ContactForm/ContactForm"
import img from '../../Images/c682c0762b30eb94c11ce44ecab78546.jpeg'

function Contact(){
    return(
        <>
        <Navbar/>
        <LandPage header="contact us" pragraph ="we offer everything is special to you" img ={img}/>
        <div className="contact-container">
        </div>
        <ContactForm/>
        <Footer/>
        </>
    )
}
export default Contact