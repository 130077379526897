import './RelativeBlog.css'
import GreenBtn from '../../GreenBtn/GreenBtn'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import img from '../../../Images/fbfc06afbf1b513d6788f5a8300f0107.jpeg'
function RelativeBlog(){
    const nav = useNavigate()
    const [data , setData] = useState([])
    const handleReadMoreBtn =(id)=>{
        nav(`/Blogs/${id}`)
    }
    useEffect(() =>{
       axios.get('https://jsonplaceholder.typicode.com/photos')
       .then(res=> {return res})
       .then(res=> setData(res.data))
    })
    data.length = 3
    const card = data.map(item => {
        return(
            <div className='relativeBlog-card'>
                <img src={img} alt="d"/>
                <div className='relativeBlog-description'>
                    <div className="relativeBlog-header">
                         <h3>title of the next article</h3>
                         <h4>tur, 20 october of 2024</h4>
                    </div>                   
                    <GreenBtn value="read more" func={()=>handleReadMoreBtn(item.id)}/>
                </div>
            </div>
        )
     })
    return(
        <div className="relativeBlog-container">
            <h1>relative blogs</h1>
            <div className='cards'>
                {card}
            </div>
        </div>
    )
}
export default RelativeBlog