import { faFacebook, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Footer.css'
const date = new Date()
function Footer(){
    return(
        <div className="footer-container">
            <div className="footer-content">
                <div>
                    <h2>Agropharma</h2>
                    <p>there are many variation of passages of lorem ipsum available, but the</p>
                    <div className="footer-icon">
                        <FontAwesomeIcon icon={faFacebook} className="social-icon" />
                        <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
                        <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
                        <FontAwesomeIcon icon={faXTwitter} className="social-icon" />
                    </div>
                </div>
                <div>
                    <h2>our links</h2>
                    <ul>
                        <li><a href='#/'>services</a></li>
                        <li><a href='#/'>products</a></li>
                        <li><a href='#/'>market place</a></li>
                        <li><a href='#/'>vlogs</a></li>
                    </ul>
                </div>
                <div>
                    <h2>market</h2>
                    <ul>
                        <li><a href='#/'> product</a></li>
                    </ul>
                </div>
                <div>
                    <h2>contact</h2>
                    <ul>
                        <li><p>75,Road Street ,200 London , England</p></li>
                        <li><a href='mailto:hamada@gmail.com'><FontAwesomeIcon icon={faEnvelope} />hmada@gmail.com</a></li>
                        <li><a href='tel:00 925 842 787'><FontAwesomeIcon icon={faPhone} /> 00 925 842 787</a></li>
                    </ul>
                </div>
            </div>
            <div className='copyRight'>
                <p>copyright &copy;{date.getFullYear()} PikyHost | Powerd by PikyHost</p>
            </div>
        </div>
    )
}
export default Footer