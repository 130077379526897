import './Slider.css'
import img1 from'../../../Images/1.png'
import img2 from'../../../Images/2.png'
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
function Slider(){
  // test

    // const handleImageClick = (e) => {
        // if (swiperRef.current) {
            // const swiper = swiperRef.current.swiper;
            // if (swiper) {
                // const clickedSlide = e.target.closest('.swiper-slide');
                // if (clickedSlide) {
                    // const slides = swiper.slides;
                    // slides.forEach((slide) => {
                        // slide.classList.remove('focused');
                    // });
                    // clickedSlide.classList.add('focused');
                // }
            // }
        // }
    // };
//          ---------------------------------
    const swiperRef = useRef(null);
    const handleNextClick = () => {
        if (swiperRef.current) {
            const swiper = swiperRef.current.swiper;
            if (swiper) {
              const currentIndex = swiper.activeIndex;
              const nextIndex = (currentIndex + 1) % swiper.slides.length;
              swiper.slideTo(nextIndex);
            }
          }
      };
      const handlePrevClick = () => {
        if (swiperRef.current) {
          const swiper = swiperRef.current.swiper;
          if (swiper) {
            const currentIndex = swiper.activeIndex;
            let prevIndex = currentIndex - 1;
            if (prevIndex < 0) {
              prevIndex = swiper.slides.length - 1; // Wrap around to the last slide
            }
            swiper.slideTo(prevIndex);
          }
        }
      };
    return(
        <div className='slider-container'>
          <div className="leftIcon" onClick={handlePrevClick}>
            <FontAwesomeIcon className='angleLeft' icon={faAngleLeft} />
          </div>
            <Swiper
                ref={swiperRef}
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 1800,
                  modifier: 1,
                  slideShadows: false,
                }}
                // pagination={true}
                modules={[EffectCoverflow,
                  //  Pagination
                  ]}
                className="mySwiper"
              >
                <SwiperSlide className='AboutSlide'>
                  <img src={img1} alt="img"/>
                </SwiperSlide>
                <SwiperSlide className='AboutSlide'>
                  <img src={img2} alt="img"/>
                </SwiperSlide>
                <SwiperSlide className='AboutSlide'>
                  <img src={img1} alt="img"/>
                </SwiperSlide>
                <SwiperSlide className='AboutSlide'>
                  <img src={img2} alt="img"/>
                </SwiperSlide>
                <SwiperSlide className='AboutSlide'>
                  <img src={img1} alt="img"/>
                </SwiperSlide>
              </Swiper>
              <div className="rightIcon" onClick={handleNextClick}>
                  <FontAwesomeIcon className='angleRight' icon={faAngleRight} />
              </div>
        </div>

        
    )
}
export default Slider