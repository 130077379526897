import './HomeAbout.css'
import img from '../../../Images/23a41c29e41310839e02c502d70c8bbe.png'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
function HomeAbout(){
    const [isFlipped , setFlip] = useState(false)
    const nav = useNavigate()
    const handleFlip = (e)=>{
        setFlip(!isFlipped)
    }
    const handleSeeMoreBtn =()=>{
        nav('/about')
    }
    return(
        <div className="homeAbout-container">
            <div className='homeAbout-content'>
                <div className='one'>
                    <h1>about us</h1>
                    <h2>We offer everything that is special to you</h2>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, </p>
                    <div className='btn'>
                        <button onClick={handleSeeMoreBtn}>see more</button>
                    </div>
                </div>
                <div className='two'>
                    <img onClick={handleFlip} style={{
                         "filter":isFlipped?"grayScale(50)":"none",
                         transform: isFlipped ? "rotateY(180deg)" : "none" 
                        }} src={img} alt = "img" />
                </div>
            </div>
        </div>
    )
}
export default HomeAbout