import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { useEffect, useState } from 'react'
import img from '../../../Images/c682c0762b30eb94c11ce44ecab78546.jpeg'
import GreenBtn from '../../GreenBtn/GreenBtn'
import './HomeBlogs.css'
function HomeBlogs(){
    const [blog , setBlog]= useState([])
    useEffect(()=>{
        axios.get('https://jsonplaceholder.typicode.com/photos')
        .then(res => setBlog(res.data))
    })
    blog.length = 3
    const card = blog.map(item =>{
        return(
            <div className='homeBlog-card'>
                <div className='image'>
                    <img src={img} alt="img" />
                </div>
                <div className='header'>
                    <h3>the title of the article</h3>
                    <h4>tur, 20 october of 2024</h4>
                </div>
                <p>there are many variation of passages of lorem ipsum available,</p>
                <GreenBtn value = "read more"/>
            </div>
        )
    }) 
    return(
        <div className="homeBlogs-container">
            <h1>our blogs</h1>
            <h2>unique products with our high quality</h2>
            <div className='blogCards'>
                {card}
                <div className='homeCategOuter'>
                <div className='empty-width'>
                    </div>
                    <button>sea more <FontAwesomeIcon icon={faAngleRight}/></button>
                </div>
            </div>
        </div>
    )
}
export default HomeBlogs