import axios from "axios"
import { useEffect, useState } from "react"
import Footer from "../Footer/Footer"
import Navbar from "../Navbar/Navbar"
import './Category.css'
import img from'../../Images/categ2.png'
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faAngleRight } from "@fortawesome/free-solid-svg-icons"
function Category(){
    const nav = useNavigate()
    const handleViewCategory=(id)=>{
        nav(`/categories/${id}`)
    }
    const handleHomeBtn=()=>{
        nav('/')
    }
    const handleCategoryBtn=()=>{
        nav('/')
    }
    const [category , setCategory]= useState([])
    useEffect(() => {
        axios.get('https://jsonplaceholder.typicode.com/photos')
        .then(res => setCategory(res.data))
    })
    category.length = 20
    const categ = category.map((item)=>{
        return(
            <div className="category-card">
                <img src={img} alt="img"/>
                <div>
                    <h3>categroy name</h3>
                    <p>put the product description here</p>     
                </div>
                <button onClick={()=>handleViewCategory(item.id)}>view category</button>
            </div>
        )
    })
    return(
        <>
            <Navbar/>
            <div className="category-container">
                <div className="relative-links">
                    <button onClick={handleHomeBtn}>home</button>
                    <FontAwesomeIcon icon={faAngleRight}/>
                    <button onClick={handleCategoryBtn}>our category</button>
                    <FontAwesomeIcon icon={faAngleRight}/>
                    <button>see more</button>
                </div>
                <div className="cards">
                    {categ}
                </div>
            </div>
            <Footer/>
        </>
    )
}
export default Category