import Footer from "../Footer/Footer";
import LandPage from "../LandPage/LandPage";
import Navbar from "../Navbar/Navbar";
import img from '../../Images/copy.jpeg'
import './About.css'
import Slider from "./Slider/Slider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function About(){
    const nav  = useNavigate()
    const handleHomeBtn=()=>{
        nav('/')
    }
    const handleAboutBtn=()=>{
        nav('/about')
    }
    return(
        <> 
            <Navbar/>
            <LandPage header="About Us" pragraph ="we offer everything is special to you" img ={img}/>
            <div className="about-container">
                <div className="relative-links">
                    <button onClick={handleHomeBtn}>home</button>
                    <FontAwesomeIcon icon={faAngleRight}/>
                    <button onClick={handleAboutBtn}>About</button>
                </div>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, </p>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, </p>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, </p>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, </p>
            </div>
                <Slider/>
            <Footer/>
        </>
    )
}
export default About