import './ContactForm.css'
function ContactForm(){
    return(
        <div className="contactForm-container">
            <div>
                <h1>personal information</h1>
                <form>
                    <div className='inputOne'>
                        <input type="text" placeholder="first name"/>
                        <input type="text" placeholder="last name"/>
                    </div>
                    <div className='inputTwo'>
                        <input type="date"  placeholder="date"/>
                        <input type="mobile"placeholder="phone nubmer"/>
                        <input type="email" placeholder="email"/>
                    </div>
                    <div>
                        <textarea className='area' placeholder='message'></textarea>
                    </div>
                    <div className='checkbox'>
                        <input type="checkbox" />
                        <p>i agree for all information</p>
                    </div>
                    <button>send</button>
                </form>
            </div>
        </div>
    )
}
export default ContactForm