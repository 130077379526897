import'./Tab.css'
function Tab(props){
    return(
        <div className="tab">
            <div className='tab'>
                <img src={props.img} alt="tab" />
                <h1>{props.header}</h1>
            </div>
        </div>
    )
}
export default Tab