import './SingleBlog.css'
import axios from "axios"
import { useEffect, useState } from "react"
import img from'../../../Images/fbfc06afbf1b513d6788f5a8300f0107.jpeg'
import Navbar from '../../Navbar/Navbar'
import Footer from '../../Footer/Footer'
import RelativeBlog from '../RelativeBlog/RelativeBlog'
import { useParams } from 'react-router-dom'

function SelectedBlog(){
    const param = useParams()
    const [item ,setItem] = useState([])
    useEffect(() =>{
        axios.get(`https://jsonplaceholder.typicode.com/photos/${param.BlogID}`)
        .then(res=> setItem(res.data))
     })
     console.log(item)
    return(
        <>
            <Navbar/>
            <div className="selectedBlog-container">
                <img src={img} alt="img"/>
                <div className='selectedBlog-header'>
                    <h3>title of the next article</h3>
                    <h4>tur, 20 october of 2024</h4>
                </div>
                <p>
                    there are many variations of passages of lorem ipsum available,
                    there are many variations of passages of lorem ipsum available,
                    there are many variations of passages of lorem ipsum available,
                    there are many variations of passages of lorem ipsum available,
                    there are many variations of passages of lorem ipsum available,
                    there are many variations of passages of lorem ipsum available,
                </p>
            </div>
            <RelativeBlog/>
            <Footer/>
        </>
    )
}
export default SelectedBlog