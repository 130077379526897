import HomeSlider from './HomeSlider/HomeSlider'
import './HomeLanPage.css'
function HomeLanPage(){

    return(
        <div className="homeSlider-container">
          <div>
            <h1>AGROPHARMA</h1>
            <p>make your pet Happy, come and leave your pets with us</p>
            <button>check our products</button>
          </div>
          <HomeSlider/>
        </div>
    )
}
export default HomeLanPage