import LandPage from "../LandPage/LandPage";
import img from '../../Images/1c64adf40db0fddfd2742beb31347e79.jpeg'
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Item from "./Item/Item";
import ProductFilter from "../ProductFilter/ProductFilter";
function Product(){
    return(
        <>
        <Navbar/>
        <LandPage header = "our product" pragraph="we offer every thing that is special for you" img ={img} />
        <div className="product-container">
            <ProductFilter/>
            <Item/>
        </div>
        <Footer/>
        </>
    )
}
export default Product