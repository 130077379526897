import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './GreenBtn.css'
function GreenBtn(props){
    return(
        <div className='greenBtn-container'>
            <div className='btn'>
                <button onClick={props.func}>{props.value} <FontAwesomeIcon icon={faAngleRight}/></button>
            </div>
        </div>
    )
}
export default GreenBtn