import './HomeSlider.css'
import img1 from '../../../../Images/tab4.jpeg'
import img2 from '../../../../Images/categ2.png'
import img3 from '../../../../Images/categ3.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

function HomeSlider(){

    return(
        <div className="landPage-container">
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <div>
                <img src={img1} alt="img" />
                <img src={img2} alt="img" />
                <img src={img3} alt="img" />
                <img src={img2} alt="img" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={img1} alt="img" />
                <img src={img2} alt="img" />
                <img src={img3} alt="img" />
                <img src={img2} alt="img" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={img1} alt="img" />
                <img src={img2} alt="img" />
                <img src={img3} alt="img" />
                <img src={img2} alt="img" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={img1} alt="img" />
                <img src={img2} alt="img" />
                <img src={img3} alt="img" />
                <img src={img2} alt="img" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={img1} alt="img" />
                <img src={img2} alt="img" />
                <img src={img3} alt="img" />
                <img src={img2} alt="img" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
    )
}
export default HomeSlider