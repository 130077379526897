import './App.css';
import { BrowserRouter as Router , Route,Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import Contact from './Components/Contact/Contact';
import About from './Components/About/About';
import Blogs from './Components/Blogs/BlogsContainer';
import SingleBlog from './Components/Blogs/SingleBlog/SingleBlog';
import Product from './Components/Product/Product';
import ProductDetails from './Components/Product/ProductDetails/ProductDetails';
import Market from './Components/Market/Market';
import Category from './Components/Category/Category';
import SingleCategory from './Components/Category/SingleCategory/SingleCategory';
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Routes>
            <Route path='/' element = {<Home />}  />
            <Route path='/about' element = {<About/>}  />
            <Route path='/blogs' element = {<Blogs />}  />
            <Route path='/Blogs/:BlogID' element = {<SingleBlog />}  />
            <Route path='/products' element = {<Product />}  />
            <Route path='/products/:ID' element = {<ProductDetails />}  />
            <Route path='/contact' element = {<Contact/>}  />
            <Route path='/market' element = {<Market/>}  />
            <Route path='/categories/:categoryID' element={<SingleCategory/>} />
            <Route path='/categories' element={<Category/>} />
          </Routes>
        </Router>
      </header>
    </div>
  );
}

export default App;
