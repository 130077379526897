import './HomeCateg.css'
import img1 from '../../../Images/categ1.png'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'


function HomeCateg(){
    const [homeCategory , setHomeCategory] = useState([])
    const nav = useNavigate()

    const handleViewBtn =(id)=>{
        nav(`/categories/${id}`)
    }
    
    const handleSeeMorBtn=()=>{
        nav('/categories')
    }

    // const [isFlipped , setFlip] = useState(false)
    const [flippedCards, setFlippedCards] = useState({});
    const handleFlip = (e)=>{
        setFlippedCards(prevState => ({
            ...prevState,
            [e]: true
        }));
    }
    const handleMouseLeave = (e)=>{
        setFlippedCards(prevState => ({
            ...prevState,
            [e]: false
        }));
        console.log(flippedCards)
    }
    useEffect(()=>{
        axios.get('https://jsonplaceholder.typicode.com/photos')
        .then(res=> setHomeCategory(res.data))
    })
    homeCategory.length = 4
    const card = homeCategory.map((item) =>{
        return(
            <div className='card'>
                <div className={`flippedCard ${flippedCards[item.id] ? "flip" : "notFlip"}`} 
                    onMouseEnter={()=>handleFlip(item.id)} onMouseLeave={()=>handleMouseLeave(item.id)}
                >
                        {!flippedCards[item.id]?
                            <div className='firstCard'>        
                                <img src={img1} alt="img"/>
                            </div>:
                            <div className='secondCard'>
                                <h3>description</h3>
                                <p>put the description here</p>
                            </div>}
                </div>
                <div>
                    <h3>category name</h3>
                    <p>put the product description</p>
                </div>
                <button onClick={()=>handleViewBtn(item.id)}>view category</button>
            </div>
        )
    })
    return(
        <div className="homeCateg-container">
            <h1>our category</h1>
            <h2>unique products with our high quality</h2>
            <div className='cards'>
                {card}
                <div className='homeCategOuter'>
                    <div className='empty-width'>
                    </div>
                    <button onClick={handleSeeMorBtn} >see more <FontAwesomeIcon icon={faAngleRight}/></button>
                </div>
            </div>
        </div>
    )
}
export default HomeCateg