import LandPage from "../LandPage/LandPage";
import Navbar from "../Navbar/Navbar";
import img from'../../Images/fbfc06afbf1b513d6788f5a8300f0107.jpeg'
import Footer from "../Footer/Footer";
import BlogsItem from "./BlogsItem/BlogsItem";
import BlogsFilter from "../BlogsFilter/BlogsFilter";
import './BlogsContainer.css'
function Blogs(){
    return(
        <>
        <Navbar/>
        <LandPage header="blogs" pragraph ="we offer everything is special to you" img ={img}/>
        <div className="blogs-container">
            <BlogsItem/>
            <BlogsFilter/>
        </div>
        <Footer/>
        </>
    )
}
export default Blogs