import './HomeContact.css'
import img from '../../../Images/399a6e48df5cf7328f5ba681c01761fc.png'
function HomeContact(){
    return(
        <div className="homeContact-container">
            <h1>Contact us</h1>
            <h2>to receive everything new from our world</h2>
            <form>
                <input type="text" placeholder="your name"/>
                <input type="number" placeholder="your phone number"/>
                <input type="email" placeholder="your email"/>
                <textarea placeholder="typing your message" className='area'>

                </textarea>
                <button>join now</button>
            </form>
            <div>
                <img src={img} alt="img" />
            </div>
        </div>
    )
}
export default HomeContact