import './Market.css'
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import LandPage from '../LandPage/LandPage';
import img from '../../Images/dfbfdb1579c8dc7799369088a4fe8fee.png'
import tab1 from '../../Images/tab1.jpeg'
import tab2 from '../../Images/tab2.jpeg'
import tab3 from '../../Images/tab3.jpeg'
import tab4 from '../../Images/tab4.jpeg'
import tab5 from '../../Images/tab5.jpeg'
import Tab from './Tab/Tab';
import axios from 'axios';
import { useEffect, useState } from 'react';

// 
import medicen from '../../Images/db8aed210d7d6a68fb0f7874432dbe58.png'
import { useNavigate } from 'react-router-dom';
function Market(){
    const nav = useNavigate()
    const handleAddBtn =()=>{
        nav('/contact')
    }
    const [data , setData] = useState([])
    useEffect(()=>{
        axios.get('https://jsonplaceholder.typicode.com/photos')
        .then(res => {return res })
        .then(res => setData(res.data))
    })
    data.length=10
    const card = data.map(item => {
        return(
            <div className='card'>
                <img src={medicen} alt="d"/>
                <h3>product name</h3>
                <p>Injectable Solution Injectable</p>
                <button onClick={handleAddBtn}>Add to card</button>
            </div>
        )
    })
    return(
        <>
        <Navbar/>
        <LandPage header="market place" pragraph ="we offer every thing that is special to you" img={img}/>
        <div className="market-container">
            <div className='market-tabs'>
                <Tab img={tab5} header="horse product"/>
                <Tab img={tab4} header="cat product"/>
                <Tab img={tab3} header="dog product"/>
                <Tab img={tab2} header="rabbit product"/>
                <Tab img={tab1} header="crow product"/>
            </div>
            <div className='market-cards'>
                {card}
            </div>
        </div>
        <Footer/>
        </>
    )
}
export default Market