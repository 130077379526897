import './BlogsItem.css'
import axios from "axios"
import { useEffect, useState } from "react"
import img from '../../../Images/fbfc06afbf1b513d6788f5a8300f0107.jpeg'
import { useNavigate } from 'react-router-dom'
import GreenBtn from '../../GreenBtn/GreenBtn'
function BlogsItem(){
    const nav = useNavigate()
    const handleReadMoreBtn = (id)=>{
        nav(`/Blogs/${id}`)
    }
    const [data , setData] = useState([])
    useEffect(() =>{
       axios.get('https://jsonplaceholder.typicode.com/photos')
       .then(res=> {return res})
       .then(res=> setData(res.data))
    })
    data.length = 10
    const card = data.map(item => {
       return(
           <div className='blog-card'>
               <img onClick={()=>handleReadMoreBtn(item.id)} src={img} alt="d"/>
               <div className='blog-description'>
                   <div className="blog-header">
                        <h2>title of the next article</h2>
                        <h3>tur, 20 october of 2024</h3>
                   </div>
                   <div className="blog-pragraph">
                       <p>there are many variations of passages of lorem ipsum available,</p>
                   </div>
                   <GreenBtn value="read more" func={()=>handleReadMoreBtn(item.id)}/>
               </div>
           </div>
       )
    })
    return(
        <div className="blog-container">
                {card}
        </div>
    )
}
export default BlogsItem